import React from "react";

const SvgSocial = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "ico ico-4 brief-social",
  height = "100%",
  viewBox = "0 0 126 119.1"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <g id="Group_304" data-name="Group 304" transform="translate(3 3)">
    <g className="like" id="like">
      <path id="Path_133" data-name="Path 133" d="M2997.49,2273.792l-.121-.039v0l-.33-.084h0l-.72-.184-.023-.006-.137-.035h-.06a1.358,1.358,0,0,0-.188-.014.7.7,0,0,0-.389.1l-.028.019-.034,0a5.424,5.424,0,0,0-2.922,1.144,5.321,5.321,0,0,0-2.1,4.212,12.793,12.793,0,0,1-3.2,8.471l-5.732,6.487-.083.094-.1-.078a2.967,2.967,0,0,0-1.82-.626h-12.325a2.961,2.961,0,0,0-2.958,2.958v27.732a2.961,2.961,0,0,0,2.958,2.957H2979.5a2.959,2.959,0,0,0,2.235-1.023l.071-.082.094.052a16.671,16.671,0,0,0,8.012,2.062H3011.7a6.664,6.664,0,0,0,6.584-6.906c-.028-.577-.049-4.628,0-20.654,0-.566,0-.911,0-.964a6.594,6.594,0,0,0-6.581-6.591l-10.126-.03h-.2l.081-.186a18.564,18.564,0,0,0-.977-16.337A5.352,5.352,0,0,0,2997.49,2273.792Zm-20.945,25.5v21.683h-6.41v-21.817h6.41Zm35.15-.594a.677.677,0,0,1,.677.676c0,.331,0,1.092-.007,2.142V2302l0,.6c-.034,8.868-.057,17.268.01,18.676a.675.675,0,0,1-.675.712h-21.782a10.741,10.741,0,0,1-6.737-2.385l-.668-.541-.05-.04v-17.313l.033-.038,9.177-10.384a18.672,18.672,0,0,0,4.483-9.649l.084-.576.174.555a12.59,12.59,0,0,1-.4,8.651l-1.808,4.283a2.958,2.958,0,0,0,2.715,4.108Z" transform="translate(-2891.077 -2259.886)" fill="#721aad"/>
      <path id="Path_134" data-name="Path 134" d="M2977,2222.609h6.614a13.686,13.686,0,0,1,13.646,13.646v53.967a13.686,13.686,0,0,1-13.646,13.646l-8.49.236-16.492,13.19V2304.1l-28.985-.236A13.686,13.686,0,0,1,2916,2290.222v-53.967a13.686,13.686,0,0,1,13.646-13.646h26.983" transform="translate(-2855.703 -2222.609)" fill="none" stroke="#721aad" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
    </g>
    <g className="hash" id="hash">
      <path id="Path_135" data-name="Path 135" d="M2689.681,2393.488v-5.661a10.9,10.9,0,0,1,10.868-10.868h42.98a10.9,10.9,0,0,1,10.868,10.868v42.98a10.9,10.9,0,0,1-10.868,10.868l-6.762.188-13.134,10.5v-10.5l-23.084-.188a10.9,10.9,0,0,1-10.868-10.868v-23.546" transform="translate(-2689.681 -2335.837)" fill="#fff" stroke="#721aad" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
      <path id="Path_136" data-name="Path 136" d="M2741.547,2456.138a2.961,2.961,0,0,0,2.957,2.958h5.97l-.026.155-1.108,6.7a2.957,2.957,0,0,0,2.435,3.4,2.954,2.954,0,0,0,3.4-2.436l1.275-7.71.018-.112h4.178l-.025.155-1.108,6.7a2.961,2.961,0,0,0,2.435,3.4,3,3,0,0,0,.486.04,2.947,2.947,0,0,0,2.916-2.476l1.275-7.71.018-.111h8.63a2.958,2.958,0,0,0,0-5.915h-7.652l.026-.155.659-3.992.019-.111h6.948a2.958,2.958,0,0,0,0-5.915h-5.97l.026-.155,1.108-6.7a2.957,2.957,0,0,0-2.435-3.4,2.99,2.99,0,0,0-.49-.041,2.958,2.958,0,0,0-2.91,2.476l-1.275,7.711-.018.111h-4.178l.026-.155,1.108-6.7a2.958,2.958,0,1,0-5.836-.965l-1.275,7.71-.019.111h-8.63a2.958,2.958,0,0,0,0,5.915h7.652l-.025.155-.66,3.991-.018.112H2744.5A2.961,2.961,0,0,0,2741.547,2456.138Zm15.926-3.113.66-3.991.019-.112h4.177l-.025.155-.66,3.991-.019.112h-4.178Z" transform="translate(-2727.729 -2376.734)" fill="#721aad"/>
    </g>
    <g className="heart" id="heart">
      <path id="Path_137" data-name="Path 137" d="M2922.958,2623.005v4.735a7.741,7.741,0,0,1-7.718,7.718l-4.8.133-9.327,7.46v-7.46l-16.393-.133a7.741,7.741,0,0,1-7.718-7.718v-30.522a7.741,7.741,0,0,1,7.718-7.718h30.522a7.741,7.741,0,0,1,7.718,7.718V2609.6" transform="translate(-2827.094 -2491.752)" fill="#fff" stroke="#721aad" strokeLinecap="round" strokeLinejoin="round" strokeWidth="6"/>
      <path id="Path_120" data-name="Path 120" d="M2422.585,13176.023s6.525-7.61,11.2,0-11.2,15.53-11.2,15.53-14.479-7.375-10.8-15.53S2422.585,13176.023,2422.585,13176.023Z" transform="translate(-2350 -13059.563)" fill="#45e5d9"/>
      <path id="Path_138" data-name="Path 138" d="M2910.627,2642.851a11.888,11.888,0,0,0,3.571,8.514c1.912,2,4.65,3.893,8.877,6.135a2.959,2.959,0,0,0,2.771,0c7.471-3.961,12.448-7.968,12.448-14.648a9.086,9.086,0,0,0-9.066-9.425,8.2,8.2,0,0,0-4.716,1.453l-.077.053-.076-.054a8.289,8.289,0,0,0-4.808-1.525,8.726,8.726,0,0,0-2.056.249A9.181,9.181,0,0,0,2910.627,2642.851Zm8.262-3.5a2.768,2.768,0,0,1,.65-.078,2.479,2.479,0,0,1,2.292,1.485,2.985,2.985,0,0,0,2.626,1.6h.06a2.976,2.976,0,0,0,2.622-1.706,2.178,2.178,0,0,1,2.1-1.3,3.157,3.157,0,0,1,.669.072,3.192,3.192,0,0,1,2.472,3.433c0,3.092-2.128,5.432-7.853,8.633l-.065.036-.065-.036c-5.724-3.2-7.853-5.541-7.853-8.633C2916.542,2640.13,2918.339,2639.482,2918.889,2639.349Z" transform="translate(-2851.762 -2523.92)" fill="#721aad"/>
    </g>
  </g>
  </svg>
);
export default SvgSocial;
