import React from "react";
import { Layout, SubpageHeader } from "../components";
import { Link } from "gatsby";
import {
  SvgCamp,
  SvgBranding,
  SvgFacebook,
  SvgSocial,
} from "../components/brief";

const Brief = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Bezpłatna konsultacja marketingowa - wypełnij brief",
        description:
          "Wypełnij nasz brief i zacznij skutecznie działać w Internecie. Dzięki naszym usługom osiągniesz wymierne efekty i zbudujesz silną markę w sieci. Wypełnij brief i otrzymaj bezpłatną konsultację marketingową.",
        href: pathname,
        lang: "pl",
      }}
    >
      <SubpageHeader title="brief" />

      <section className="container-fluid brief_main_section">
        <h4 className="align-center">
          Wybierz usługę, którą jesteś zainteresowany/a
        </h4>

        <div className="row">
          <div className="col-lg-3 col-sm-6 brief-col">
            <Link
              to="/brief-kampania-kompleksowa/"
              className="brief_link brief_link__branding"
            >
              <SvgBranding />
              <p>
                Kampania <br /> kompleksowa
              </p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6 brief-col">
            <Link to="/brief-google/" className="brief_link brief_link__camp">
              <SvgCamp />
              <p>
                Kampania <br /> Google Ads
              </p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6 brief-col">
            <Link
              to="/brief-facebook/"
              className="brief_link brief_link__facebook"
            >
              <SvgFacebook />
              <p>
                Kampania <br /> Facebook Ads
              </p>
            </Link>
          </div>

          <div className="col-lg-3 col-sm-6 brief-col">
            <Link
              to="/brief-social-media/"
              className="brief_link brief_link__social"
            >
              <SvgSocial />
              <p>
                Prowadzenie <br /> social media
              </p>
            </Link>
          </div>
        </div>
        <p className="desc">
          Powyżej oddajemy w Twoje ręce nasz brief. Zebrane w nim informacje
          pomogą nam określić potrzeby Twojej strony i marki. Bazując na
          konkretnych celach będziemy w stanie przygotować bezpłatną strategię. 
          <br />
          <br />
          Cały proces powinien zająć nie więcej niż 15 minut. W każdej chwili
          możesz wrócić do poprzednich pytań. Po otrzymaniu go skontaktujemy się
          z Tobą i wspólnie ustalimy termin konsultacji marketingowej.
        </p>
      </section>
    </Layout>
  );
};

export default Brief;
