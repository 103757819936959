import React from "react";

const SvgCamp = ({
  style = {},
  width = "100%",
  className = "ico ico-2 brief-campaign",
  height = "100%",
  viewBox = "0 0 126 119.1",
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <g
      id="Group_302"
      data-name="Group 302"
      transform="translate(-1474.948 -1202.253)"
    >
      <g
        id="Rectangle_117"
        className="rectangle"
        data-name="Rectangle 117"
        transform="translate(1510.786 1321.949) rotate(-150)"
        fill="#fff"
        stroke="#721aad"
        strokeMiterlimit="10"
        strokeWidth="6"
      >
        <rect width="31.917" height="110.322" rx="15.959" stroke="none" />
        <rect
          x="-3"
          y="-3"
          width="37.917"
          height="116.322"
          rx="18.959"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_118"
        className="circle"
        data-name="Rectangle 118"
        transform="translate(1510.786 1321.949) rotate(-150)"
        fill="#fff"
        stroke="#721aad"
        strokeMiterlimit="10"
        strokeWidth="6"
      >
        <rect width="31.917" height="31.827" rx="15.913" stroke="none" />
        <rect
          x="-3"
          y="-3"
          width="37.917"
          height="37.827"
          rx="18.913"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_119"
        data-name="Rectangle 119"
        transform="matrix(0.866, -0.5, 0.5, 0.866, 1522.547, 1226.408)"
        fill="#45e5d9"
        stroke="#721aad"
        strokeMiterlimit="10"
        strokeWidth="6"
      >
        <rect width="31.917" height="109.153" rx="15.959" stroke="none" />
        <rect
          x="-3"
          y="-3"
          width="37.917"
          height="115.153"
          rx="18.959"
          fill="none"
        />
      </g>
    </g>
  </svg>
);
export default SvgCamp;
