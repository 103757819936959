import React from "react";

const SvgBranding = ({
  width = "100%",
  className = "ico ico-1 brief-branding",
  height = "100%",
  viewBox = "-10 0 134 129"
}) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <g id="Group_306" data-name="Group 306" transform="translate(-446 -1175)">
      <rect id="Rectangle_114" data-name="Rectangle 114" width="11" height="74" rx="5.5" transform="translate(531.918 1180.288) rotate(-13)" fill="#45e5d9"/>
      <g id="Group_303" data-name="Group 303" transform="translate(-42.884 -461.091)">
        <path id="Path_129" data-name="Path 129" d="M525,1698.543a2.675,2.675,0,1,0-3.2-2.02v.006a2.68,2.68,0,0,0,3.2,2.014Zm-15.916,14.4,5.959,26.266a10.69,10.69,0,1,0,20.875-4.623c-.009-.042-.019-.084-.029-.126l-3.55-15.648,7.817-1.782a2.678,2.678,0,0,0,2.014-3.2l-1.544-6.783,51.862,5.523a8.024,8.024,0,0,0,9.766-9.565l-3.933-17.333,7.964-6.542a2.678,2.678,0,0,0,.912-2.662l-2.366-10.431a2.676,2.676,0,0,0-1.97-2l-10-2.449-4.369-19.244a7.944,7.944,0,0,0-5.349-5.854l-.1-.031A7.943,7.943,0,0,0,575.5,1638L529,1670.2l-25.551,5.825a18.719,18.719,0,0,0,5.636,36.913h0Zm21.593,22.705a5.346,5.346,0,0,1-10.388,2.529c-.013-.052-.024-.1-.035-.155l-5.916-26.082,10.424-2.375,5.915,26.083Zm.476-22.056-1.184-5.215,5.211-1.188,1.184,5.216Zm68.829-44.759,1.635,7.208-4.621,3.8-2.813-12.426Zm-21.079-26.719a2.67,2.67,0,0,1,4.374,1.408l13.766,60.666a2.673,2.673,0,0,1-3.336,3.172,9.9,9.9,0,0,0-1.472-.194L577.672,1643c.92-.645,1.03-.7,1.228-.876h0Zm-5.965,4.158,13.679,60.289-47.235-5.029-6.278-27.666Zm-78.377,50.977a13.376,13.376,0,0,1,10.07-16.008l23.452-5.344,5.919,26.073-23.452,5.35a13.367,13.367,0,0,1-15.988-10.07Zm22.034.467a2.671,2.671,0,0,0-3.2-2.015h0l-5.213,1.186a2.674,2.674,0,0,1-3.2-2.013,2.673,2.673,0,0,0-5.213,1.187,8.022,8.022,0,0,0,9.591,6.042l5.211-1.188a2.677,2.677,0,0,0,2.014-3.2h0Z" transform="translate(0 0)" fill="#721aad" fillRule="evenodd"/>
        <path id="Path_130" className="bot-line" data-name="Path 130" d="M1103.667,1859.028l-8.533-6.457a2.672,2.672,0,0,0-3.348,4.166c.04.032.081.063.122.093l8.53,6.458a2.672,2.672,0,1,0,3.34-4.172q-.055-.044-.113-.086h0Z" transform="translate(-475.158 -170.43)" fill="#721aad" fillRule="evenodd"/>
        <path id="Path_131" className="mid-line" data-name="Path 131" d="M1122.541,1754.224l-16.671,3.582a2.677,2.677,0,0,0,1.119,5.235l16.661-3.583a2.676,2.676,0,0,0-1.118-5.235h0" transform="translate(-485.393 -93.21)" fill="#721aad" fillRule="evenodd"/>
        <path id="Path_132" className="top-line" data-name="Path 132" d="M1061.387,1658.007l-5.666,9.077a2.672,2.672,0,0,0,4.449,2.961q.043-.064.081-.13l5.668-9.074a2.672,2.672,0,1,0-4.455-2.953c-.026.04-.051.08-.075.121h0Z" transform="translate(-447.122 -16.263)" fill="#721aad" fillRule="evenodd"/>
      </g>
    </g>
  </svg>
);
export default SvgBranding;
