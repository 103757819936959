import React from "react";

const SvgFacebook = ({
  style = {},
  fill = "#fff",
  width = "100%",
  className = "ico ico-3 brief-facebook",
  height = "100%",
  viewBox = "0 0 126 119.1"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    x="0px"
    y="0px"
  >
    <g id="Layer_2" data-name="Layer 2">
        <g id="icons">
            <rect id="tło" className="cls-1" x="5.22" y="4.5" width="101" height="107" rx="12.54" />
            <path id="fiol_stroke" data-name="fiol stroke" className="cls-2" d="M65.93,111.5H95.26A16.26,16.26,0,0,0,111.5,95.27v-79A16.26,16.26,0,0,0,95.26,0h-79A16.25,16.25,0,0,0,0,16.24v79A16.25,16.25,0,0,0,16.24,111.5H52.56v-6.28H16.24a10,10,0,0,1-10-10v-79a10,10,0,0,1,10-10h79a10,10,0,0,1,10,10v79a10,10,0,0,1-10,10H65.93v6.28Z" />
            <g id="nozka" className="nozka">
                <rect className="cls-3" x="51.73" y="78.36" width="14.98" height="33.15" />
                <rect className="cls-2" x="65.91" y="78.36" width="6.28" height="33.14" />
                <rect className="cls-2" x="46.26" y="78.36" width="6.28" height="33.15" />
            </g>
            <g id="gora_f" className="gora" data-name="gora f">
                <path id="Path_140" data-name="Path 140" className="cls-3" d="M85.56,25.16H69.05A17.34,17.34,0,0,0,51.73,42.47V57.9H38.63v15h13.1v5.47h15V72.89H83.44l.12-.68,2.22-13.37.16-.93H66.71V45.75a5.62,5.62,0,0,1,5.61-5.61h14v-15Z" />
                <path id="Path_141" data-name="Path 141" className="cls-2" d="M72.32,52.43h-.13V45.61H91.84V19.69H69.05A22.81,22.81,0,0,0,46.26,42.47v10H33.16V78.36H52.54V72.08H39.44V58.71h13.1V42.47A16.52,16.52,0,0,1,69.05,26H85.56V39.33H72.32a6.43,6.43,0,0,0-6.41,6.42v13H85L82.76,72.08H65.91v6.28H88.08L92.4,52.43Z" />
            </g>
        </g>
    </g>
  </svg>
);
export default SvgFacebook;
