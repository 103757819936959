import React from "react";

const BriefNav = ({ currentStep, setStep, steps }) => {
  return (
    <div className={`brief_nav ${currentStep === 5 ? "hidden" : ""}`}>
      {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
      <ul className="brief_nav__row">
        {Array(steps)
          .fill(null)
          .map((item, index) => (
            <li
              key={index}
              className={`brief_nav__item ${
                index + 1 <= currentStep ? "active" : ""
              }`}
              onClick={() => setStep(index + 1)}
              onKeyDown={() => setStep(index + 1)}
            >
              <button type="button" aria-label="Przejdź do kroku" />
            </li>
          ))}
      </ul>
    </div>
  );
};

export default BriefNav;
